import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import LoginQuiz from "./LoginQuiz";
import Pending from "./Pending";
import { AuthProvider } from "@descope/react-sdk";
import { useSearchParams } from "react-router-dom";

const App = () => {
  // get the base URL and project ID from query parameters
  const [searchParams] = useSearchParams();
  const baseUrl = searchParams.get("baseUrl");
  const projectId = searchParams.get("projectId");

	return  (
		<AuthProvider
			projectId={projectId || "P2WW9HM7L4dT7vN6WWTi5toT8SEr"}
			baseUrl={baseUrl || "https://auth.prod.asafshen.dev"}
			sessionTokenViaCookie={true}
		>
			<Routes>
				<Route index element={<Home />} />
				<Route path="/login" element={<Login />} />
				<Route path="/quiz" element={<LoginQuiz />} />
				<Route path="/pending" element={<Pending />} />
			</Routes>
		</AuthProvider>
	)
};

export default App;
